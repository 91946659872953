<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    :mode="mode"
    :disabled="validationDisabled"
  >
    <div
      class="group"
      :class="classes"
    >
      <label :for="id">
        {{ fieldLabel }}
        <span
          v-if="(required && !hideAsterisk) || requiredFromBack"
          class="asterisk"
        >
          *
        </span>
      </label>
      <input
        :id="id"
        v-model="valueModel"
        :name="fieldName"
        :type="type"
        :disabled="disabled"
        :maxlength="maxlength"
        :class="
          [className,
           { 'has-right-icon': allowCopy },
           blur && 'blurry-text',
           readonly && 'read']"
        :autocomplete="autocomplete"
        v-bind="ariaInput"
        :placeholder="placeholder"
        :readonly="readonly"
        @input="onChange"
        @click="blurred"
      />
      <inline-svg
        v-if="allowCopy"
        :src="iconCopy"
        class="svg-icon"
        @click="onCopy"
      >
      </inline-svg>
      <label
        v-if="labelUnderField"
        class="example-label"
      >
        {{ labelUnderField }}
      </label>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
        <span
          v-if="redLine"
          class="red-line"
        >
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialInput',
  extends: BaseMaterialInput,
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'url',
          'text',
          'password',
          'tel',
          'search',
          'number',
          'email',
        ].includes(value);
      },
    },
    mode: {
      type: String,
      default: 'passive',
    },
    className: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideAsterisk: {
      type: Boolean,
      default: false,
    },
    requiredFromBack: {
      type: Boolean,
      default: false,
    },
    handleChange: {
      type: Function,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    blur: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    redLine: {
      type: Boolean,
      default: false,
    },
    validationDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noBlur: false,
    };
  },
  methods: {
    onChange(e) {
      if (e.target.value > 0) {
        this.handleChange();
      }
    },
    blurred() {
      if (this.blur && this.readonly && this.id) {
        if (this.noBlur) {
          this.noBlur = false;
          return document.getElementById(this.id)
            && document.getElementById(this.id).classList.add('blurry-text');
        }
        this.noBlur = true;
        return document.getElementById(this.id)
            && document.getElementById(this.id).classList.remove('blurry-text');
      }

      return null;
    },
  },
};
</script>

<style scoped>
  input {
    background: #383b40;
    border: 0;
    border-radius: 0;
    height: 48px;
    margin-top: 10px;
    padding: 15px;
  }

  input.has-right-icon {
     padding-right: 45px;
  }

  input.read {
    opacity: 0.7;
  }

  .inspector-input {
    background: #222;
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: #5A5D62;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .group.failed {
    margin-bottom: 10px;
  }

  .group.failed input {
    border-bottom: 1px solid #fb3951;
  }

  label {
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    text-align: left;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .tree-modal-inputs {
    padding: 13px;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .red-line {
    border-bottom: 1px solid #fb3951;
  }

  .asterisk {
    color: #fb3951;
  }

  .dark-input {
    background: #222;
    border: 0;
    border-radius: 0;
    margin-top: 10px;
    padding: 15px;
  }

  .blurry-text {
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .svg-icon {
    bottom: 11px;
    cursor: pointer;
    position: absolute;
    right: 7px;
  }

  label.example-label {
    position: absolute;
    right: 0;
    bottom: -20px;
    padding-top: 5px;
    font-size: 14px;
  }
</style>
