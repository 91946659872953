const CLIENT_ID = '1_3swyfr3983cw0swo404kokkc0ko08swkgckg848ck0k8wooc0s';
const CLIENT_SECRET = '5v88rt75ud8g8oowgkkocco8sgssook08sgwgggk44sgoo4kg8';

const CONFIG = {
  chunkUpload: 52428800,
  date: {
    format: 'MM/DD/YYYY HH:mm:ss',
    dateFormat: 'MM/DD/YYYY',
    monthYearDateFormat: 'MMM, YYYY',
    monthDayDateFormat: 'MMM DD',
    timeNY: 5,
    timeChicago: 6,
    timeDenver: 7,
    timeLA: 8,
    tzNY: 'America/New_York',
    tzChicago: 'America/Chicago',
    tzDenver: 'America/Denver',
    tzLA: 'America/Los_Angeles',
  },
  userList: {
  	userActiveStatus: 'activated',
  },
  alert: {
    timeout: 5000,
  },
  routing: {
    redirectFromAddAcc: 600,
    myProfile: '/settings/user-profile',
  },
  redirectTimeout: 1000,
  auth: {
    auth: {
      grantType: 'password',
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
    },
    anonAuth: {
      grantType: 'client_credentials',
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      scopeRole: 'ROLE_ANONYMOUS',
    },
    refresh: {
      grantType: 'refresh_token',
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
    },
  },
  player: {
    bitmovinKey: 'ec1c9dc8-9a07-4ed1-964a-245a962cec13',
  },
  statuses: {
    failedStatus: 500,
    badRequest: 400,
    notFound: 404,
    noContent: 204,
  },
  errorMessages: {
    failedToLoadPreviewVideo: 'An error occurred while playing the preview video',
    errorDescriptionInspector: 'The field can have no more than 500 characters',
    forgotPassValidErrorMessage: 'Please enter valid email format',
    commonServerError: 'Something went wrong',
    invalidAnonTokenError: 'The password reset link is no longer valid.'
      + ' Please request another password reset email from the sign in page',
    login: {
      invalid_grant: 'Email address and/or password is invalid',
    },
    disableAccMessage: 'Your Account has been disabled.'
      + ' Please contact support if you feel this is a mistake.',
    upload: {
      invalidFileFormat: 'File format is invalid',
      failedSizeUpload: "File size can't be less than 0 MB",
      failedUpload: 'File uploading is failed',
      errorTypeFormat: 'invalidType',
      errorTypeSize: 'File size can be no more that 10GB',
      invalidTranscode: 'Transcoding process has been failed',
    },
    uploadXML: {
      type: 'text/xml',
      fileSizeError: "XML size can't be less than 0 kB",
      fileFormatError: 'XML File format is invalid',
    },
    noVideoMessage: 'Video not found',
    unknown: 'UNKNOWN_ERROR',
    copyToClipboard: 'Failed to copy data to clipboard',
    lockedMessage: 'Media Item is locked',
    lockedError: 'EDITING_NOT_AVAILABLE',
    dndErrorMessage: 'Media Item cannot be moved',
    noFoundMeta: 'METADATA_FOR_MEDIA_FOLDER_NOT_FOUND',
    subscriptionCreateNotAvailable: 'Checkout session can not be created',
    subscriptionUpdateNotAvailable: 'Payment update session can not be created',
    notFoundChartReport: 'Report not found',
    reportIntervalInvalidType: 'REPORT_INTERVAL_INVALID',
    reportIntervalIsLongType: 'REPORT_INTERVAL_TOO_LONG',
    reportIntervalStartGreaterThanNowType: 'REPORT_START_GREATER_THAN_TODAY',
    reportIntervalStartGreaterThanEndType: 'REPORT_START_GREATER_THAN_FINISH',
    reportIntervalIsLongMessage: 'The selected date range cannot exceed 13 months',
    reportIntervalStartGreaterThanEndMessage: 'The start date cannot be after the end date',
    reportIntervalStartGreaterThanNowMessage: 'The start date cannot be after the current date',
    removeFallbackNotAvailable: 'This Media Item is used as the schedule Fallback Media and cannot be deleted from the system',
    recordingTypeError: 'HAS_CHANGES',
    recordingErrorMessage: 'Recording has already begun',
    scheduleConflictsError: 'Please remove conflicts before saving',
    akamaiAppCreateError: 'Akamai App cannot be created',
    akamaiAppSyncError: 'Akamai App cannot be synchronized',
    deliveryTargetNotFoundError: 'Delivery Target not found',
    limitedDateMessage: 'Note: Analytics data retention is currently limited to 13 months. Contact your account manager to request unlimited data retention.',
    subscriptionProviderConfigError: 'Credentials are invalid',
  },
  upload: {
    maxUploadPerCent: 100,
    bytesInMb: 1000000,
    uploadedColor: '#1FEE65',
    failedColor: '#fb3951',
    successStatus: 'Uploaded',
    failedStatus: 'Failed',
    cancelStatus: 'Canceled',
    maxFileSize: 10737418240,
  },
  transcoding: {
  	transcoding: 'transcoding',
    completed: 'completed',
    error: 'error',
    uploading: 'uploading',
    packaging: 'packaging',
  },
  support: {
    successMessage: 'Your message has been successfully sent to the support team',
  },
  successMessages: {
    addPersonMessage: 'The Person has been successfully added',
    editPersonMessage: 'The Person has been successfully updated',
    editCmsPageMessage: 'The Page has been successfully updated',
    addFeedMessage: 'The Feed has been successfully added',
    editFeedMessage: 'The Feed has been successfully updated',
    addAccountMessage: 'The Account has been successfully created',
    editAccountMessage: 'The Account has been successfully updated',
    editUserMessage: 'The User has been successfully updated',
    addUserMessage: 'The User has been successfully added',
    editUserProfileMessage: 'Your profile has been successfully updated',
    editUserPasswordMessage: 'Your password has been successfully updated',
    editMediaFolderMessage: 'The Folder has been successfully updated',
    addMediaFolderMessage: 'The Folder has been successfully added',
    deleteMediaFolderMessage: 'The Folder has been successfully deleted',
    resentRegLink: 'The Link has been successfully resent',
    copyToClipboard: 'Data has been successfully copied',
    addCustomRoleMessage: 'The Role has been successfully added',
    editCustomRoleMessage: 'The Role has been successfully updated',
    updateMetaDataMessage: 'The Folder Metadata has been successfully saved',
    deleteMetaDataMessage: 'The Folder Metadata has been successfully deleted',
    editMediaMessage: 'The Media has been successfully updated',
    editLiveMessage: 'The Live Workflow has been successfully updated',
    editScheduleSettingsMessage: 'The Schedule Settings have been successfully saved',
    editScheduleTargetMessage: 'The Delivery Target has been successfully updated',
    addScheduleTargetMessage: 'The Delivery Target has been successfully added',
    deleteScheduleTargetMessage: 'The Delivery Target has been successfully deleted',
    enableScheduleTargetStateMessage: 'The Delivery Target has been successfully enabled',
    disableScheduleTargetStateMessage: 'The Delivery Target has been successfully disabled',
    addCustomFieldMessage: 'The Custom Field has been successfully added',
    editCustomFieldMessage: 'The Custom Field has been successfully updated',
    disabledFieldMessage: 'The Custom Field has been successfully disabled',
    enabledFieldMessage: 'The Custom Field has been successfully enabled',
    filesPreparedForUpload: 'successMessages.upload.filesPreparedForUpload',
    addSubtitleAsset: 'The Subtitle asset has been successfully added',
    editSubtitleAsset: 'The Subtitle asset has been successfully updated',
    replaceSubtitleAsset: 'The Subtitle asset has been successfully replaced',
    deleteSubtitleAsset: 'The Subtitle asset has been successfully deleted',
    editServerMessage: 'The Server has been successfully updated',
    addServerMessage: 'The Server has been successfully added',
    addEventMessage: 'The Event has been successfully scheduled',
    addEventGroupMessage: 'The Event Group has been successfully added',
    deleteEventGroupMessage: 'The Event Group has been successfully deleted',
    deleteEventMessage: 'The Event has been successfully deleted',
    editEventGroupMessage: 'The Event Group has been successfully updated',
    editEventMessage: 'The Event has been successfully updated',
    removedMediaMessage: 'The Media has been successfully deleted',
    editLocalizationMessage: 'The Language has been successfully updated',
    addLocalizationMessage: 'The Language has been successfully added',
    removedLocalizationMessage: 'The Language has been successfully deleted',
    removedRoleMessage: 'The Role has been successfully deleted',
    addAssetRoleMessage: 'The Asset Role has been successfully added',
    editAssetRoleMessage: 'The Asset Role has been successfully updated',
    editPlaylistMessage: 'The Playlist has been successfully updated',
    editSeriesMessage: 'The Series has been successfully updated',
    editSeasonsMessage: 'The Season has been successfully updated',
    editFeatureMessage: 'The Feature has been successfully updated',
    editCategoryMessage: 'The Category has been successfully updated',
    editGenreMessage: 'The Genre has been successfully updated',
  },
  sort: {
    sortUp: 'ASC',
    sortDown: 'DESC',
  },
  modals: {
    deleteManuallyRowInCms: 'Are you sure you want to delete this Row Item?',
    deleteCmsPage: 'Are you sure you want to delete this Page?',
    deleteRowInCms: 'Are you sure you want to delete this Row?',
    deleteTabInCms: 'Are you sure you want to delete this Tab?',
    deleteBannerInCms: 'Are you sure you want to delete this Banner?',
    deleteBannerInCmsBySliders: 'Are you sure you want to remove this Banner?',
    deleteSliderInCms: 'Are you sure you want to delete this Slider?',
    disabledPersonMessage: 'Are you sure you want to disable this Person?',
    enabledPersonMessage: 'Are you sure you want to enable this Person?',
    disabledConfirmationMessage: 'Are you sure you want to disable this Account?',
    enabledConfirmationMessage: 'Are you sure you want to enable this Account?',
    akamaiAppCreateConfirmationMessage: 'Are you sure you want to create Akamai App for this account?',
    akamaiAppSyncConfirmationMessage: 'Are you sure you want to sync Akamai App for this account?',
    removeMediaMessage: 'Are you sure you want to delete this Media?',
    replaceMessage: 'Are you sure you want to replace this Media?',
    lockedMediaMessage: 'Are you sure you want to lock this Media Item? No changes will be saved',
    unLockedMediaMessage: 'Are you sure you want to unlock this Media Item?',
    disabledRoleMessage: 'Are you sure you want to disable this Custom Role?',
    enabledRoleMessage: 'Are you sure you want to enable this Custom Role?',
    deleteCustomRoleMessage: 'Are you sure you want to delete this Custom Role?',
    errorDisabledRole: 'The Custom Role is assigned to a Media Item(s)',
    deleteMetaConfirmMessage: 'Are you sure you want to delete Metadata?',
    errorDisabledPerson: 'The Person is assigned to a Media Item(s)',
    confirmRemoveFallback: 'Are you sure you want to delete this Fallback Media Item?',
    recorderToggleConfirmationMessage: 'Disabling recording for this event will disable {targets} Delivery Targets.',
    errorDeleteCustomField: 'This field is assigned to entity',
    deleteCustomFieldMessage: 'Are you sure you want to delete this Custom Field?',
    disabledFieldMessage: 'Are you sure you want to disable this Custom Field?',
    enabledFieldMessage: 'Are you sure you want to enable this Custom Field?',
    eventWarningMessage: 'The Mark In/Out settings for this Media will be ignored. Would you like to continue?',
    addExistingUserToAccountMessage: 'This email address is already associated with a Broadcast Cloud Account',
    deleteEventGroupMessage: 'Deleting this Group will remove all future Events and will also terminate any current Event in progress. Would you like to proceed?',
    deleteEventMessage: 'Are you sure you want to delete this Event?',
    changeTvAppsEventGroup: 'Changing Event Group will terminate any current event in progress on TV apps. Would you like to proceed?',
    deleteLanguageMessage: 'Are you sure you want to delete this Language?',
    deleteBrushfireMessage: 'Are you sure you want to delete this Brushfire Event?',
    disabledRoleConfirmationMessage: 'Are you sure you want to disable this Asset Role?',
    enabledRoleConfirmationMessage: 'Are you sure you want to enable this Asset Role?',
    deleteUserMessage: 'Are you sure you want to delete this User? The User will be removed immediately from the Broadcast Cloud system and all Accounts',
    deleteUserAdminMessage: 'Are you sure you want to delete this User? The User will be removed immediately from this Account',
    deleteAssetRoleMessage: 'Are you sure you want to delete Asset Role?',
    deletePlaylistMessage: 'Are you sure you want to delete this Playlist?',
    deleteSeriesMessage: 'Are you sure you want to delete this Series and all its Seasons?',
    deleteSeasonMessage: 'Are you sure you want to delete this Season?',
    deleteFeatureMessage: 'Are you sure you want to delete this Feature?',
    disableCategoryTitle: 'Disable Category',
    enableCategoryTitle: 'Enable Category',
    disabledCategoryConfirmationMessage: 'Are you sure you want to disable this Category?',
    enabledCategoryConfirmationMessage: 'Are you sure you want to enable this Category?',
    categoryInUseWarningMessage: 'This Category is in use',
    deleteCategoryTitle: 'Delete Category',
    deleteCategoryConfirmationMessage: 'Are you sure you want to delete this Category?',
    deleteCategoryConfirmationNameMessage: 'To confirm, please enter the name of the Category you wish to delete',
    disableGenreTitle: 'Disable Genre',
    enableGenreTitle: 'Enable Genre',
    disabledGenreConfirmationMessage: 'Are you sure you want to disable this Genre?',
    enabledGenreConfirmationMessage: 'Are you sure you want to enable this Genre?',
    genreInUseWarningMessage: 'This Genre is in use',
    deleteGenreTitle: 'Delete Genre',
    deleteGenreConfirmationMessage: 'Are you sure you want to delete this Genre?',
    deleteGenreConfirmationNameMessage: 'To confirm, please enter the name of the Genre you wish to delete',
  },
  roles: {
    ROLE_ADMIN: 'Admin',
    ROLE_MANAGER: 'Manager',
    ROLE_SUPER_ADMIN: 'Super Admin',
    ROLE_READ_ONLY: 'Read-Only',
  },
  roleList: [
    {
      name: 'Admin',
      code: 'ROLE_ADMIN',
    },
    {
      name: 'Manager',
      code: 'ROLE_MANAGER',
    },
    {
      name: 'Read-Only',
      code: 'ROLE_READ_ONLY',
    },
    {
      name: 'Super Admin',
      code: 'ROLE_SUPER_ADMIN',
    },
  ],
  deliveryTargetTypes: [
    {
      name: 'Custom RTMP',
      code: 'custom',
    },
    {
      name: 'YouTube',
      code: 'youtube',
    },
    {
      name: 'Facebook',
      code: 'facebook',
    },
  ],
  activationLinkStatus: {
    pending: 'Pending',
    activated: 'Activated',
    expired: 'Expired',
  },
  stream: {
    routeToStream: 'addLive',
  },
  profile: {
    routeToProfile: 'user_profile_settings',
  },
  sortByMediaList: [
    {
      name: 'Name',
      value: 'mi.name',
    },
    {
      name: 'AirDate',
      value: 'mi.airDate',
    },
    {
      name: 'Runtime',
      value: 'mi.duration',
    },
  ],
  schedule: {
    successMessage: '',
    successSave: 'Your daily Schedule has been successfully saved',
    successSaveAndStartPublish: 'The Schedule has been saved and publish action started',
    successPublish: 'The Broadcast Schedule has been successfully published',
    publishMessage: 'Publish will interrupt any current Broadcast and replace it with this Schedule. Are you sure you want to publish?',
  },
  facebookDeliveryTargets: {
    facebookDestType: [
      {
        name: 'My Timeline',
        code: 'timeline',
      },
      {
        name: 'Page',
        code: 'account',
      },
      {
        name: 'Group',
        code: 'group',
      },
    ],
    facebookPrivacy: [
      {
        name: 'Only Me',
        code: 'SELF',
      },
      {
        name: 'Friends',
        code: 'ALL_FRIENDS',
      },
      {
        name: 'Friends of Friends',
        code: 'FRIENDS_OF_FRIENDS',
      },
      {
        name: 'Public',
        code: 'EVERYONE',
      },
    ],
    loginStatus: {
      login: 'Log in to Facebook to authorize your account as a Facebook Live stream target.',
      process: 'Please wait ...',
      logged: ' is currently logged into Facebook. Click the button below if you want to log out.',
    },
  },
  confirmationLeaveMessage: 'Leave without saving?',
  contentTypeList: [
    {
      name: 'Movie',
      code: 'Movie',
    },
    {
      name: 'Event',
      code: 'Event',
    },
    {
      name: 'Episode',
      code: 'Episode',
    },
    {
      name: 'Trailer',
      code: 'Trailer',
    },
    {
      name: 'Extra',
      code: 'Extra',
    },
  ],
  metadataTypeList: [
    {
      name: 'Series',
      code: 'Series',
    },
    {
      name: 'Season',
      code: 'Season',
    },
    {
      name: 'Event',
      code: 'Event',
    },
    {
      name: 'Feature',
      code: 'Feature',
    },
  ],
  iTunesMetadataTypeList: [
    {
      code: 'episodic',
      name: 'Episodic',
    },
    {
      code: 'serial',
      name: 'Serial',
    },
  ],
  feedFormatsAdvanced: [
    {
      code: 'rss',
      name: 'RSS',
    },
    {
      code: 'json',
      name: 'JSON',
    },
    {
      code: 'itunes',
      name: 'iTunes',
    },
  ],
  iTunesFileTypes: [
    {
      code: 'video',
      name: 'Video',
    },
    {
      code: 'audio',
      name: 'Audio',
    },
  ],
  explicitITunes: [
    {
      code: 'explicit',
      name: 'Explicit',
    },
    {
      code: 'clean',
      name: 'Сlean',
    },
  ],
  tooltips: {
    editCMSPage: 'Edit Page',
    removeRoleTitle: 'Delete Role',
    disableRoleTitle: 'Disable Role',
    enableRoleTitle: 'Enable Role',
    editRole: 'Edit Role',
    editPerson: 'Edit Person',
    editFeed: 'Edit Feed',
    disablePersonTitle: 'Disable Person',
    enablePersonTitle: 'Enable Person',
    posterMode: 'Open Poster Image',
    playerMode: 'Back to the player',
    moreDetails: 'More Details',
    lock: 'Lock Media Item',
    unlock: 'Unlock Media Item',
    editFieldTitle: 'Edit Field',
    enableFieldTitle: 'Enable Field',
    disableFieldTitle: 'Disable Field',
    createAkamaiApp: 'Create Akamai App',
    syncAkamaiApp: 'Sync Akamai App',
    enableAccount: 'Enable Account',
    disableAccount: 'Disable Account',
    editAccount: 'Edit Account',
    viewAnalytic: 'View',
    generateFeedWidget: 'Generate Embed Code',
    sendUserActivationEmail: 'Send Activation Email',
    singInUnderUser: 'Sign in under User',
    editUser: 'Edit User',
    editAsset: 'Edit Asset',
    viewOnlyServer: 'View Only',
    editServer: 'Edit Server',
    removeEventGroupTitle: 'Delete Event Group',
    generateWidgetTitle: 'Generate Embed Code',
    editEventGroupTitle: 'Edit Event Group',
    standaloneEventsTitle: 'View Individual Events',
    removeEventTitle: 'Delete Event',
    editEventTitle: 'Edit Event',
    editBanner: 'Edit Banner',
    editSlider: 'Edit Slider',
    removeBanner: 'Delete Banner',
    removeSlider: 'Delete Slider',
  },
  subscriptionTypes: {
    online: 'online',
    offline: 'offline',
  },
  subscriptionRedirect: {
    statuses: {
      success: 'success',
      cancel: 'cancel',
    },
    types: {
      create: 'create',
      update: 'update',
    },
  },
  paymentStatuses: {
    pending: 'Pending',
    blocked: 'Blocked',
    active: 'Active',
    failed: 'Failed',
  },
  invoiceStatuses: {
    pending: 'Pending',
    success: 'Success',
    canceled: 'Failed',
  },
  analyticSortByList: [
    {
      name: 'Today',
      value: 'today',
    },
    {
      name: 'Last 7 Days',
      value: 'lastweek',
    },
    {
      name: 'Last 30 Days',
      value: 'last30days',
    },
  ],
  analyticDeviceSortByList: [
    {
      name: 'Browser',
      value: 'browser',
    },
    {
      name: 'Device',
      value: 'device_type',
    },
  ],
  week: [
    {
      code: 'Sunday',
      recurring: false,
    },
    {
      code: 'Thursday',
      recurring: false,
    },
    {
      code: 'Monday',
      recurring: false,
    },
    {
      code: 'Friday',
      recurring: false,
    },
    {
      code: 'Tuesday',
      recurring: false,
    },
    {
      code: 'Saturday',
      recurring: false,
    },
    {
      code: 'Wednesday',
      recurring: false,
    },
  ],
  analyticsTimeFrames: [
    {
      name: 'Last 5 Minutes',
      value: 'last5minutes',
    },
    {
      name: 'Last 30 Minutes',
      value: 'last30minutes',
    },
    {
      name: 'Last Hour',
      value: 'lasthour',
    },
    {
      name: 'Last 6 Hours',
      value: 'last6hours',
    },
    {
      name: 'Yesterday',
      value: 'yesterday',
    },
    {
      name: 'Today',
      value: 'today',
    },
    {
      name: 'Last 7 Days',
      value: 'lastweek',
    },
    {
      name: 'This Week',
      value: 'thisweek',
    },
    {
      name: 'Last 30 Days',
      value: 'last30days',
    },
    {
      name: 'This Month',
      value: 'thismonth',
    },
    {
      name: 'Last Month',
      value: 'lastmonth',
    },
    {
      name: 'Last 6 Months',
      value: 'last6months',
    },
    {
      name: 'Last 12 Months',
      value: 'last12months',
    },
    {
      name: 'This Year',
      value: 'thisyear',
    },
    {
      name: 'Custom',
      value: 'custom',
    },
  ],
  analyticFilterBy: [
    {
      name: 'by Media Title',
      value: 'byMediaTitle',
    },
  ],
  videoFormats: [
    {
      code: 'live',
      name: 'Live',
    },
    {
      code: 'vod',
      name: 'On-Demand',
    },
  ],
  defaultSelectedVideoFormats: ['live', 'vod'],
  analyticSortField: {
    title: 'title',
    views: 'views',
    uniques: 'uniques',
    hours: 'hours',
    playtime: 'playtime',
  },
  analyticTitleValues: ['country', 'state_province', 'city', 'device_type', 'browser'],
  analyticMapIconHome: 'M15.8082 7.50382L8.51295 0.208541C8.37658 0.0748716 8.19324 0 8.00228 0C7.81133 0 7.62798 0.0748716 7.49161 0.208541L0.196337 7.50382C0.102313 7.6045 0.0387188 7.72975 0.0129135 7.86507C-0.0128917 8.0004 0.000146169 8.14026 0.0505189 8.26848C0.100892 8.3967 0.18654 8.50804 0.297554 8.58961C0.408568 8.67119 0.54041 8.71966 0.677825 8.72942H1.56785V16.0247C1.56785 16.2182 1.64471 16.4037 1.78152 16.5405C1.91833 16.6774 2.10389 16.7542 2.29738 16.7542H6.38273V11.8591C6.38273 11.6656 6.45959 11.4801 6.5964 11.3432C6.73322 11.2064 6.91877 11.1296 7.11226 11.1296H8.87771C9.0712 11.1296 9.25676 11.2064 9.39357 11.3432C9.53038 11.4801 9.60724 11.6656 9.60724 11.8591V16.7469H13.6926C13.8861 16.7469 14.0716 16.6701 14.2085 16.5333C14.3453 16.3964 14.4221 16.2109 14.4221 16.0174V8.72213H15.3486C15.4828 8.70767 15.6103 8.65629 15.717 8.5737C15.8237 8.49111 15.9054 8.38054 15.953 8.25429C16.0007 8.12805 16.0124 7.99106 15.9868 7.85857C15.9612 7.72608 15.8994 7.60328 15.8082 7.50382Z',
  analyticFilterByGeo: [
    {
      value: 'country',
      name: 'Country',
    },
    {
      value: 'state_province',
      name: 'State/Region',
    },
    {
      value: 'city',
      name: 'City',
    },
  ],
  postEventAction: [
    {
      code: 0,
      name: 'Custom Text',
      defaultMessage: 'This event has concluded.',
    },
    {
      code: 1,
      name: 'Present On-Demand Video Link to the video',
      defaultMessage: 'This event has concluded. Press play to view a recording of the event.',
    },
  ],
  postEventActionLive: [
    {
      code: 0,
      name: 'Custom Text',
      defaultMessage: 'This Event has concluded.',
    },
  ],
};

export default CONFIG;
