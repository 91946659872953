<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ errors }"
    @input="handleFormChange"
  >
    <div class="flex">
      <div class="left-path">
        <span class="system">
          Scheduled Events /
          {{ isGroup || isIndividualEvent ? ' Event Groups ' : ' Standalone' }}
          <span :title="eventGroupName">
            {{ isIndividualEvent ? `/ ${getName(eventGroupName)} ` : '' }}
          </span>
        </span>
        <span
          :title="formData.name"
        >
          / Edit {{ getName(formData.name) }}
        </span>
      </div>
      <div class="right-path">
        <Button
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onRemove"
          :disabled="isAppsEventGroup"
        />
        <Button
          :title="'Cancel'"
          :classType="'orange'"
          :eventFunction="onCancel"
        />
        <Button
          :title="'Apply'"
          :classType="'primary'"
          :type="'submit'"
          :eventFunction="onSave"
          :disabled="(!formData.canEdit && formData.isTerminated)
            || formData.status === 'completed'"
        />
      </div>
    </div>
    <div class="title">
      {{ isGroup ? 'Event Group Settings' : 'Event Settings' }}
    </div>
    <Tabs
      :class="'tabs-container'"
      :onSelectTab="handleSelectTab"
    >
      <Tab
        :id="'general'"
        :name="'General info'"
        :selected="true"
        :className="[(tabErrors.general
          || hasCustomFieldErrors(getSortedFields(isGroup
            ? 'event_group_general_info'
            : 'event_general_info'), errors))
          ? 'error-tab'
          : 'tab']"
      >
        <MaterialInput
          v-model="formData.name"
          v-bind="formFields.name"
          :requiredFromBack="true"
        />
        <div v-if="!isGroup">
          <MaterialTextArea
            v-model="formData.description"
            v-bind="formFields.description"
          />
          <div class="inline-row">
            <MaterialCalendar2
              v-model="formData.date"
              v-bind="formFields.date"
              :disabled="(!formData.canEdit && !formData.isTerminated)
                || formData.status === 'in_progress'"
              :disabledDate="unavailableDates"
            />
            <div style="margin-right: 0">
              <MaterialTime
                v-model="formData.time"
                v-bind="formFields.time"
                :isManualInput="false"
                :disabled="(!formData.canEdit && !formData.isTerminated)
                  || formData.status === 'in_progress'"
                :onChangeTime="onChangeTime"
                :blockClassName="'event-form-picker'"
              />
            </div>
          </div>
          <div
            v-if="!isIndividualEvent"
            class="inline-row-bottom"
            :style="formData.type !== 'vod' ? {marginBottom: 0}: ''"
          >
            <MaterialSelect
              v-model="formData.hasPostEventPresentVideo"
              v-bind="formFields.postEventAction"
              :withEmptyField="false"
              :options="postEventActionList"
              :handleChange="onChangePostEventMessage"
            />
            <div style="margin-right: 0">
              <MaterialInput
                v-model="formData.postEventMessage"
                v-bind="formFields.postEventMessage"
              />
            </div>
          </div>
          <div
            v-if="formData.type !== 'vod'"
            class="duration-wrapper"
          >
            <MaterialTime
              v-model="formData.duration"
              v-bind="formFields.duration"
              :blockClassName="'duration'"
            />
          </div>
          <div :class="[(formData.type !== 'vod' || isIndividualEvent) ? '' : 'languages-wrapper']">
            <LanguageTagManagement
              :key="`languages${titleName}`"
              :selectedValue="formData.languages"
              :label="'Language'"
              :tagPlaceholder="'Search ...'"
              :searchable="false"
              :onChange="onLanguageChange"
              :required="false"
              :commonError="languageError"
            />
          </div>
          <div>
            <p>Video Source Information</p>
            <p>
              <span class="system">
                Title:
              </span>
              {{ mediaItem.name }}
            </p>
            <p>
              <span class="system">
                Content Type:
              </span>
              {{ formData.type === 'vod' ? mediaItem.type : 'Live' }}
            </p>
            <p v-if="formData.type === 'vod'">
              <span class="system">
                Duration:
              </span>
              {{ setMarkerAsDate(formData.duration) }}
            </p>
          </div>
          <div class="btns-wrapper">
            <Button
              :title="'Select New Source'"
              :classType="'primary'"
              :eventFunction="onSelectNewMedia"
              :disabled="(!formData.canEdit && !formData.isTerminated)
                || formData.status === 'in_progress'"
            />
            <div v-if="enabledLanguages.length">
              <Button
                :title="'Localization'"
                :classType="'primary'"
                :className="'wide-btn'"
                :srcSvg="iconFolderLocalization"
                :isReverseIcon="true"
                :eventFunction="toEvGroupLocalization"
              />
            </div>
          </div>
          <div
            v-if="hasSortedFields(
              isGroup
                ? 'event_group_general_info'
                : 'event_general_info')"
            class="custom-field-wrapper"
          >
            <div class="custom-field-title">
              Custom fields
            </div>
            <FieldValueRow
              v-for="item in getSortedFields(
                isGroup
                  ? 'event_group_general_info'
                  : 'event_general_info')"
              :key="`fieldValue${item.field.id}`"
              v-model="item.fieldValue"
              :field="item.field"
              :fieldName="getFieldName(item.field)"
              :formPrefix="formPrefix"
            />
          </div>
        </div>
        <div v-if="enabledLanguages.length && isGroup">
          <Button
            :title="'Localization'"
            :classType="'primary'"
            :className="'wide-btn'"
            :srcSvg="iconFolderLocalization"
            :isReverseIcon="true"
            :eventFunction="toEvGroupLocalization"
          />
        </div>
        <div
          v-if="isGroup && hasSortedFields(
            isGroup
              ? 'event_group_general_info'
              : 'event_general_info')"
          class="custom-field-wrapper"
        >
          <div class="custom-field-title">
            Custom fields
          </div>
          <FieldValueRow
            v-for="item in getSortedFields(
              isGroup
                ? 'event_group_general_info'
                : 'event_general_info')"
            :key="`fieldValue${item.field.id}`"
            v-model="item.fieldValue"
            :field="item.field"
            :fieldName="getFieldName(item.field)"
            :formPrefix="formPrefix"
          />
        </div>
      </Tab>
      <Tab
        v-if="renderExternal"
        :id="'targets'"
        :name="'External Targets'"
        :selected="false"
        :className="[tabErrors.targets
          ? 'error-tab'
          : 'tab']"
      >
        <ExternalDeliveryTargets
          :deliveryTargets="deliveryTargets"
          :showSync="false"
          :errors="errorsDeliveryTargets"
        />
      </Tab>
      <Tab
        v-if="!isIndividualEvent || isGroup"
        :id="'countdown'"
        :name="'Countdown & Preview'"
        :selected="false"
      >
        <Countdown
          v-if="isShowCountdown"
          :backgroundImage="backgroundImage"
          :onFileUpload="onFileUpload"
          :isGroup="isGroup"
          :updatePlayerSource="updatePlayerSource"
        />
      </Tab>
      <Tab
        v-if="getCurrentAccount.brushfireProtectionEnabled"
        :id="'protection'"
        :name="'Protection'"
        :selected="false"
      >
        <EventGroupProtection
          v-if="isGroup"
        />
        <StandaloneEventProtection
          v-else
          :eventGroupName="eventGroupName"
        />
      </Tab>
    </Tabs>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :title="deleteTitle"
      :cancelButtonTitle="'Close'"
      :confirmButtonTitle="'Confirm'"
      :bodyMessage="deleteBodyMessage"
      :onAccept="onDelete"
      :onClose="onClose"
    />
    <div v-if="isSelectMediaModalOpen">
      <FeedModal
        :accountId="getCurrentAccountId"
        :onClose="onClose"
        :onSelectFixedItem="handleSelectFixedItem"
        :newSource="true"
        :isFixedMedia="true"
        :itemTypes="(formData.type === 'livestream' || formData.type === 'livestream_url')
          ? ['livestream', 'livestream_url']
          : ['vod']"
      />
    </div>
    <EvGroupLocalization
      v-if="isLocalOpen"
      :evGroup="formData"
      :isGroup="isGroup"
      :isIndividualEvent="isIndividualEvent"
      :onClose="onCloseLocalizationModal"
    />
  </ValidationObserver>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import {
  GET_EVENT,
  EDIT_EVENT,
  DELETE_EVENT,
  GET_COUNT_EVENTS_IN_GROUP,
} from '../../store/actions/events/events';
import {
  GET_EVENT_GROUP,
  EDIT_EVENT_GROUP,
  DELETE_EVENT_GROUP,
} from '../../store/actions/events/eventGroups';
import { SET_EDIT_FORM } from '../../store/actions/common';

import BaseForm from './BaseForm.vue';
import Button from '../common/Button.vue';
import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import Countdown from '../events/Countdown.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import MaterialCalendar2 from '../inputs/MaterialCalendar2.vue';
import MaterialTime from '../inputs/MaterialTime.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import ExternalDeliveryTargets from '../liveStream/deliveryTargets/ExternalDeliveryTargets.vue';
import NotificationModal from '../NotificationModal.vue';
import FeedModal from '../feed/FeedModal.vue';
import LanguageTagManagement from '../media/LanguageTagManagement.vue';

import CONFIG from '../../constants/config';
import { IS_TIME_VALID } from '../../utils/validation/regExp';
import EventGroupProtection from '../Brushfire/EventGroupProtection.vue';
import StandaloneEventProtection from '../Brushfire/StandaloneEventProtection.vue';
import { GET_LANGUAGES_REQUEST } from '../../store/actions/localization/localizationActions';
import iconFolderLocalization from '../../assets/icons/icon-folder-localization.svg';
import EvGroupLocalization from '../localization/EvGroupLocalization.vue';
import CustomFieldFormMixin from './customFields/CustomFieldFormMixin.vue';

export default {
  name: 'EventForm',
  components: {
    EvGroupLocalization,
    StandaloneEventProtection,
    EventGroupProtection,
    Button,
    Tabs,
    Tab,
    MaterialInput,
    MaterialTextArea,
    MaterialCalendar2,
    MaterialTime,
    MaterialSelect,
    ExternalDeliveryTargets,
    NotificationModal,
    FeedModal,
    Countdown,
    LanguageTagManagement,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'event',
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isIndividualEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorsDeliveryTargets: [],
      deliveryTargs: null,
      isModalOpen: false,
      isSelectMediaModalOpen: false,
      isShowCountdown: false,
      eventGroupName: '',
      events: null,
      isAppsEventGroup: false,
      titleName: '',
      enabledLanguages: [],
      iconFolderLocalization,
      isLocalOpen: false,
      isPosterChanged: false,
      formData: {
        id: null,
        name: '',
        description: '',
        date: '',
        time: '',
        startDate: '',
        finishDate: '',
        hasPostEventPresentVideo: 1,
        postEventMessage: '',
        canEdit: true,
        backgroundUrl: '',
        playerSource: null,
        isTerminated: false,
        duration: '',
        type: '',
        languages: [],
      },
      backgroundImage: null,
      mediaItem: {
        id: null,
        name: '',
        type: 'On-Demand',
        duration: null,
      },
      itemTypes: ['vod'],
      deliveryTargets: [],
      parseTimeForBE: '',
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            min: 1,
            max: 255,
          },
        },
        description: {
          type: 'text',
          name: 'description',
          formPrefix: this.formPrefix,
          rules: {
            min: 1,
            max: 1800,
          },
        },
        date: {
          type: 'date',
          valueType: 'YYYY-MM-DD',
          name: 'date',
          format: 'DD MMM YYYY',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        time: {
          type: 'time',
          name: 'time',
          format: 'hh:mm A',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        duration: {
          type: 'time',
          name: 'duration',
          format: 'HH:mm:ss',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            regex: IS_TIME_VALID,
          },
        },
        postEventAction: {
          type: 'text',
          name: 'postEventAction',
          formPrefix: this.formPrefix,
        },
        postEventMessage: {
          type: 'text',
          name: 'postEventMessage',
          formPrefix: this.formPrefix,
          rules: {
            min: 1,
            max: 100,
          },
        },
        languages: {
          type: 'text',
          name: 'languages',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        date: [
          `${this.formPrefix}.date`,
        ],
        time: [
          `${this.formPrefix}.startDate`,
        ],
        postEventMessage: [
          `${this.formPrefix}.postEventMessage`,
        ],
        duration: [
          `${this.formPrefix}.duration`,
        ],
      },
      tabErrors: {
        general: false,
        targets: false,
        countdown: false,
      },
      deleteBodyMessage: this.isGroup
        ? CONFIG.modals.deleteEventGroupMessage
        : CONFIG.modals.deleteEventMessage,
      deleteTitle: this.isGroup ? 'Delete Event Group' : 'Delete Event',
      postEventActionList: [],
      postEventMessageDefault: '',
      successMessage: this.isGroup
        ? CONFIG.successMessages.editEventGroupMessage
        : CONFIG.successMessages.editEventMessage,
      getEvent: this.isGroup ? GET_EVENT_GROUP : GET_EVENT,
      deleteEvent: this.isGroup ? DELETE_EVENT_GROUP : DELETE_EVENT,
      submitEvent: this.isGroup ? EDIT_EVENT_GROUP : EDIT_EVENT,
    };
  },
  computed: {
    ...mapState({
      event: (state) => state.events.event,
      eventGroup: (state) => state.eventGroups.eventGroup,
    }),
    ...mapGetters([
      'getMainProfile',
      'getCurrentAccount',
    ]),
    paramsKey() {
      return (this.isGroup) ? 'eventGroupId' : 'eventId';
    },
    unavailableDates() {
      const accountCurrentDate = moment.utc().tz(this.getTimezone).date();
      const currentSystemDate = moment().date();

      if (accountCurrentDate < currentSystemDate) {
        return (date) => date < moment().subtract(2, 'd').toDate();
      }

      if (accountCurrentDate > currentSystemDate) {
        return (date) => date < moment().toDate();
      }

      return (date) => date < moment().subtract(1, 'd').toDate();
    },
    getDateTime() {
      let dateTimeString = '';

      if (this.formData.date) {
        dateTimeString += moment(this.formData.date).format('YYYY-MM-DD');
        dateTimeString += this.parseTimeForBE;
      }
      return moment.tz(dateTimeString, this.getTimezone).utc();
    },
    pathForRedirect() {
      if (this.isGroup) return '/schedule/event-group';
      if (this.isIndividualEvent) return `/schedule/event-group/event-list/${this.formData.standaloneEventGroup}`;
      return '/schedule/standalone-event';
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    renderExternal() {
      if (this.formData.type && !this.isIndividualEvent) {
        return this.formData.type === 'vod';
      }
      return !this.isIndividualEvent || this.isGroup;
    },
  },
  created() {
    const param = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, param).then((res) => {
      this.enabledLanguages = res.data;
    });

    const params = {
      accountId: this.getCurrentAccountId,
      [this.paramsKey]: this.$route.params.id,
    };

    const paramsEvents = {
      accountId: this.getCurrentAccountId,
      id: this.$route.params.id,
    };

    if (this.isGroup) {
      this.$store.dispatch(GET_COUNT_EVENTS_IN_GROUP, paramsEvents).then((res) => {
        this.events = res.pagination.total;
        this.$store.dispatch(this.getEvent, params)
          .then((res) => {
            this.initFormData(res);
            this.isShowCountdown = true;

            if (this.isIndividualEvent) {
              const params = {
                accountId: this.getCurrentAccountId,
                eventGroupId: res.standaloneEventGroup.id,
              };
              this.$store.dispatch(GET_EVENT_GROUP, params)
                .then((res) => {
                  this.eventGroupName = res.name;
                });
            }
          });
      });
    } else {
      this.$store.dispatch(this.getEvent, params)
        .then((res) => {
          this.initFormData(res);
          this.isShowCountdown = true;

          if (this.isIndividualEvent) {
            const params = {
              accountId: this.getCurrentAccountId,
              eventGroupId: res.standaloneEventGroup.id,
            };
            this.$store.dispatch(GET_EVENT_GROUP, params)
              .then((res) => {
                this.eventGroupName = res.name;
              });
          }
        });
    }
  },
  methods: {
    initFormData(item) {
      this.formData.id = item.id;
      this.formData.name = item.name;
      this.titleName = item.name;
      this.deliveryTargets = this.transformBEDeliveryTargets(item.deliveryTargets);
      this.deliveryTargs = item.deliveryTargets;
      this.formData.backgroundUrl = item.backgroundUrl;
      this.formData.playerSource = item.playerSource;
      this.formData.status = item.status;
      this.formData.isTerminated = item.isTerminated;
      this.formData.languages = item.formattedLanguages && item.formattedLanguages.map((item) => ({
        id: item.code,
        name: item.name,
      }));
      this.setCustomFields(item.customFields);

      if (this.isGroup) {
        this.formData.currentStandaloneEvent = item.currentStandaloneEvent;
        this.isAppsEventGroup = item.isAppsEventGroup;
      }

      if (!this.isGroup) {
        this.formData.description = item.description;
        this.formData.canEdit = item.canEdit;
        this.formData.startDate = item.startDate;
        this.formData.finishDate = item.finishDate;
        this.formData.date = moment.utc(item.startDate).tz(this.getTimezone).format('YYYY-MM-DD');
        this.formData.time = moment.utc(item.startDate).tz(this.getTimezone).format('hh:mm A');
        this.mediaItem.id = item.mediaItem.id;
        this.mediaItem.name = item.mediaItem.name;
        this.mediaItem.duration = item.mediaItem.duration;
        this.formData.hasPostEventPresentVideo = Number(item.hasPostEventPresentVideo);
        this.formData.postEventMessage = item.postEventMessage;
        this.formData.duration = item.mediaItem.type === 'livestream' || item.mediaItem.type === 'livestream_url'
          ? this.setMarkerAsDate(item.duration)
          : item.duration;
        this.formData.type = item.mediaItem.type;
      }

      if (this.isIndividualEvent) {
        this.formData.standaloneEventGroup = item.standaloneEventGroup.id;
      }

      if (!this.isGroup && item.mediaItem.type !== 'vod') {
        this.postEventActionList = CONFIG.postEventActionLive;
        this.postEventMessageDefault = CONFIG.postEventActionLive[0].defaultMessage;
        this.onChangePostEventMessage();
      } else {
        this.postEventActionList = CONFIG.postEventAction;
        this.postEventMessageDefault = CONFIG.postEventAction[1].defaultMessage;
      }
    },
    onLanguageChange(value) {
      this.formData.languages = value;
      this.languageError = '';
    },
    transformBEDeliveryTargets(deliveryTargets) {
      return deliveryTargets.map((item) => {
        if (item.type === 'facebook') {
          const result = {
            params: {},
            fbData: {},
          };

          Object.entries(item).forEach(([key, value]) => {
            result[key] = value;
          });

          Object.entries(item).forEach(([key, value]) => {
            result.params[key] = value;
          });

          Object.entries(item).forEach(([key, value]) => {
            result.fbData[key] = value;
          });

          return result;
        }

        return item;
      });
    },
    transformDeliveryTargets(deliveryTargets) {
      const array = deliveryTargets.map((item) => {
        delete item.status;
        delete item.id;

        if (item.type === 'facebook') {
          const result = {};
          Object.entries(item.params).forEach(([key, value]) => {
            result[key] = value;
          });

          result.type = item.type;
          result.enabled = item.enabled;
          result.recorderSync = item.recorderSync;
          delete result.status;
          delete result.id;
          delete result.facebookDestName;
          delete result.useSsl;
          if (result.facebookDestType !== 'timeline') {
            delete result.facebookPrivacy;
          }
          return result;
        }
        return item;
      });

      const result = {};
      array.forEach((item, index) => {
        result[index] = item;
      });

      return result;
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        duration: moment.duration(this.formData.duration).asSeconds(),
        languages: this.formData.languages,
      };

      if (this.formData.type === 'vod') {
        delete data.duration;
      }

      if (!this.isGroup) {
        data.description = this.formData.description;
        data.startDate = this.getDateTime;
        data.mediaItem = this.mediaItem.id;
      }

      if (this.isGroup || !this.isIndividualEvent) {
        data.deliveryTargets = this.transformDeliveryTargets(this.deliveryTargets);
        data.backgroundImage = this.backgroundImage;
      }

      if (this.isGroup) {
        delete data.duration;
        delete data.languages;
      }

      if (!this.isGroup && !this.isIndividualEvent) {
        data.hasPostEventPresentVideo = Boolean(this.formData.hasPostEventPresentVideo);
        data.postEventMessage = this.formData.postEventMessage || this.postEventMessageDefault;
      }

      if (this.isIndividualEvent) {
        data.standaloneEventGroup = this.formData.standaloneEventGroup;
      }

      if (!this.formData.canEdit && !this.formData.isTerminated) {
        delete data.startDate;
        delete data.mediaItem;
        delete data.standaloneEventGroup;
      }

      if (!this.isPosterChanged) {
        delete data.backgroundImage;
      }

      if (this.formData.status === 'in_progress') {
        delete data.startDate;
      }

      data.fields = this.getFieldsData();

      return {
        accountId: this.getCurrentAccountId,
        [this.paramsKey]: this.formData.id,
        data,
      };
    },
    onSave() {
      this.$refs[this.validationObserver].validate()
        .then((success) => {
          if (!success) {
            return;
          }
          this.submit();
        });
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.isPosterChanged = false;
      this.$router.push(this.pathForRedirect);
    },
    onSubmitFail(error) {
      const generalErrors = error.form_errors ? error.form_errors.children : null;
      if (generalErrors && generalErrors.name) {
        this.tabErrors.general = true;
      }

      const errorObj = error.form_errors;
      let deliveryTargetsItem = null;
      if (errorObj.children && errorObj.children.deliveryTargets) {
        deliveryTargetsItem = errorObj.children.deliveryTargets;
        this.tabErrors.targets = true;
        this.deliveryTargets = this.transformBEDeliveryTargets(this.deliveryTargs);
      }

      this.errorsDeliveryTargets = deliveryTargetsItem && deliveryTargetsItem.children
        ? deliveryTargetsItem.children : [];

      this.formErrors = this.getServerFormErrors(error);
      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onRemove() {
      this.isModalOpen = true;
    },
    onDelete() {
      const params = {
        accountId: this.getCurrentAccountId,
        [this.paramsKey]: this.formData.id,
      };

      this.$store.dispatch(this.deleteEvent, params)
        .then(() => {
          this.$router.push(this.pathForRedirect);
          this.isModalOpen = false;
        });
    },
    onCancel() {
      this.$router.push(this.pathForRedirect);
    },
    onClose() {
      this.isModalOpen = false;
      this.isSelectMediaModalOpen = false;
    },
    handleSelectTab(id) {
      if (id === 'general') {
        this.tabErrors.general = false;
      }

      if (id === 'targets') {
        this.tabErrors.targets = false;
      }
    },
    getName(name) {
      return name.length > 50 ? `${name.slice(0, 50)}...` : name;
    },
    onChangeTime(t) {
      this.parseTimeForBE = `T${t.data.HH}:${t.data.mm}:00`;
    },
    onChangePostEventMessage() {
      const action = Number(this.formData.hasPostEventPresentVideo);
      this.formData.postEventMessage = this.postEventActionList
        .find((el) => el.code === action)
        .defaultMessage;
      this.postEventMessageDefault = this.formData.postEventMessage;
    },
    onSelectNewMedia() {
      this.isSelectMediaModalOpen = true;
    },
    handleSelectFixedItem(item) {
      this.mediaItem.id = item.id;
      this.mediaItem.name = item.name;
      this.mediaItem.duration = item.duration;
    },
    setMarkerAsDate(value) {
      return moment.utc(value * 1000).format('HH:mm:ss');
    },
    onFileUpload(file) {
      this.isPosterChanged = true;
      this.backgroundImage = file;
    },
    updatePlayerSource() {
      const params = {
        accountId: this.getCurrentAccountId,
        [this.paramsKey]: this.$route.params.id,
      };

      return this.$store.dispatch(this.getEvent, params)
        .then((res) => {
          this.initFormData(res);
          this.isShowCountdown = true;
        });
    },
    toEvGroupLocalization() {
      this.isLocalOpen = true;
    },
    onCloseLocalizationModal() {
      this.isLocalOpen = false;
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
      this.errorTab = null;
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .system {
    color: #4c5057;
  }

  .right-path {
    display: flex;
    justify-content: space-between;
  }

  .inline-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    max-width: 800px;
  }

  .inline-row-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    max-width: 800px;
  }

  .inline-row > *,
  .inline-row-bottom > * {
    flex: 1;
    margin-right: 20px;
    max-width: 400px;
  }

  .tabs-details > div {
    width: 100%;
    max-width: 800px;
  }

  .poster-image {
    margin-bottom: 20px;
    display: flex;
  }

  img {
    width: 100%;
  }

  .player {
    height: auto;
    margin-left: 5px;
    width: 528px;
  }

  .overlay-text {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 30px;
    width: 800px;
    margin-top: 150px;
    text-align: center;
  }

  .overlay-text span {
     font-size: 18px;
  }

  .event-form-picker {
    margin-right: 0;
  }

  .duration {
    margin-right: 0;
  }

  .duration-wrapper {
    width: 49%;
  }

  .languages-wrapper {
    margin-top: -20px;
  }

  .btns-wrapper {
    display: flex;
  }

  .custom-field-wrapper {
    margin-top: 20px;
  }

  .custom-field-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
</style>
